import { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { postRequest } from '../utils/axios';

interface FormData {
  email: string;
  password: string;
  code: string;
}

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
    code: '',
  });

  const [isCodeInput, setCodeInput] = useState(false);
  const [qr, setQr] = useState();

  const sendLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      return await postRequest(
        'auth/login',
        {},
        {
          email,
          password,
        }
      );
    } catch (err) {
      alert('Wrong login or password');
    }
  };

  const generateQr = async () => {
    try {
      return await postRequest('auth/2fa/generate', {}, {});
    } catch (err) {}
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const getQr = async () => {
    const qrImage = await generateQr();
    if (qrImage) {
      setQr(qrImage.data);
    }
  };

  const getSecondStep = async () => {
    const result = await sendLogin({
      email: formData.email,
      password: formData.password,
    });

    if (result?.data?.status !== 'ok') return;

    if (!result?.data?.isAuthenticatorConnect) {
      getQr();
    }

    setCodeInput(true);
  };

  const sendCode = async (code: string) => {
    try {
      return await postRequest(
        'auth/2fa/authenticate',
        {},
        { twoFactorAuthenticationCode: code }
      );
    } catch (err) {
      alert('Wrong code');
    }
  };

  const handleSubmit = async () => {
    const result = await sendCode(formData.code);

    if (result?.data) {
      setAuth(true);
      navigate(from, { replace: true });
    }
  };

  return (
    <div className="login-wrapper">
      <h1 className="login-title">Login</h1>
      {isCodeInput ? (
        <div className="input-wrapper">
          {qr && <img width="300" height="300" src={qr} />}
          <form>
            <div className="input-wrapper">
              <label>
                Code:
                <input
                  type="text"
                  name="code"
                  value={formData.code}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleSubmit();
                    }
                  }}
                />
              </label>
            </div>
          </form>
          <button onClick={handleSubmit}>Submit</button>
        </div>
      ) : (
        <>
          <form>
            <div className="input-wrapper">
              <label>
                Login:
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      getSecondStep();
                    }
                  }}
                />
              </label>
            </div>
            <br />
            <div className="input-wrapper">
              <label>
                Password:
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      getSecondStep();
                    }
                  }}
                />
              </label>
            </div>
            <br />
          </form>
          <button onClick={getSecondStep}>Next</button>
        </>
      )}
    </div>
  );
};

export default Login;
